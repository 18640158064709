/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.container {
  max-width: 90vw;
}

.search-button {
  margin-left: 15px;
  margin-bottom: 15px;
}

@import "styles/table.css"