.toast {
    position: fixed;
    z-index: 1000;
    right: 1%;
    min-width: 300px;
    text-align: end;
}

.toast.success {
    background: #28a745;
}

.toast.error {
    background: #dc3545;
}