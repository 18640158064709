.spinner-border {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.total-value {
    font-weight: bold;
    margin-left: 5px;
    margin-right: 20px;
}