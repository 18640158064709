a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

nav {
  background-color: white;
  margin-bottom: 16px;
}

.navlink-content {
  color: #343a40;
}
.navlink-content:hover,.navlink-content.selected {
  color: #0056b3;
}
.navlink-content:active {
  color: #0076d3;
}

.logout-btn {
  margin-left: 20px;
}

.nav-bar-navigation {
  font-size: 0.9rem;
}

.nav-bar-header {
  display: flex;
  flex-direction: column;
}

.nav-bar-header > label {
  font-size: 0.75rem;
  color: grey;
  line-height: 0.8;
}