thead th.sort {
    cursor: pointer;
    min-width: 8em;
}

thead th.sort i {
    position: absolute;
    opacity: 0.5;
    font-size: 16px;
}

thead th.sort i.selected {
    opacity: 1;
}

thead th.sort i:last-child {
    padding-top: 1px;
}

thead th.sort span {
    float: right;
    padding: 1px 7px;
}