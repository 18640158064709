table {
    font-size: 14px;
    overflow-y: scroll;
}

tbody tr:hover {
    background-color: rgba(27, 110, 194, 0.1) !important;
}

tbody tr:hover .sticky-end {
    background-color: white !important;
    box-shadow: inset 0px 0px 0 100px rgba(27, 110, 194, 0.1);
}

tbody tr:hover .sticky-begin {
    background-color: white !important;
    box-shadow: inset 0px 0px 0 100px rgba(27, 110, 194, 0.1);
}

thead th {
    background-color: rgba(27, 110, 194, 1);
    color: white;
}

.sticky-end {
    right: 0px;
    position: sticky;
    z-index: 1;
    filter: drop-shadow(-1px 0 1px rgba(0, 0, 0, 0.2));
}

.sticky-begin {
    left: 0px;
    position: sticky;
    z-index: 1;
    filter: drop-shadow(2px 0 1px rgba(0, 0, 0, 0.2));
}

thead th .sticky-end, thead th .sticky-begin {
    z-index: 2;
}

tbody tr:nth-child(even),
tbody tr:nth-child(even) .sticky-end,
tbody tr:nth-child(even) .sticky-begin {
    background-color: white;
}

tbody tr:nth-child(odd),
tbody tr:nth-child(odd) .sticky-end,
tbody tr:nth-child(odd) .sticky-begin  {
    background-color: rgba(242,242,242,255);
}