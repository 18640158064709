.status {
    border-radius: 50%;
    padding: 5px;
    margin-right: 7px;    
}

.status+label {
    margin-bottom: 0;    
}

.status-none {
    background-color: #aaaaaa;
}

.status-processing {
    background-color: #f0ad4e;
}

.status-success {
    background-color: #22bb33;
}

.status-error {
    background-color: #c91d21;
}

.btn-refresh {
    margin-left: 8px;
}